import _ from 'lodash'

import 'sweetalert2/dist/sweetalert2.min.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VModal from 'vue-js-modal'
import  Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2';
import axios from 'axios';

axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

Vue.use(VueSweetalert2)
Vue.use(axios)
Vue.use(VModal)
window._ = _
Vue.component('affiliate-tracking', () =>
    import('./components/front-end/affiliate-tracking.vue'));
Vue.component('supper-admin-affiliate-index', () =>
    import('./components/back-end/supper-admin/affiliate/index.vue'));
if(jQuery('.wrapper-vu-js').length>0) {
    const app = new Vue({
        el: '.wrapper-vu-js',
        methods: {}
    });
}
